// ----------------------------------------------------------------------

export default function DateCalendar(theme) {
  return {
    // MuiCalendarPicker: {
    //   styleOverrides: {
    //     root: {
    //       '& p, button, span, div': {
    //         fontSize: '0.25rem',
    //       },
    //     },
    //   },
    // },
    // PrivatePickersToolbar: {
    //   styleOverrides: {
    //     root: {
    //       '& p, button, span, div': {
    //         fontSize: '0.25rem',
    //       },
    //     },
    //   },
    // },
  };
}
