import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Backdrop, Box, CircularProgress } from '@mui/material';
//
import ProgressBar from './ProgressBar';
import { useSelector } from '../redux/store';
import Logo from './Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Backdrop)(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  loadable: PropTypes.bool,
};

export default function LoadingScreen({ loadable, ...other }) {
  const { openPageLoadingCount } = useSelector((x) => x.settings);

  const isDisplay = useMemo(() => loadable || openPageLoadingCount > 0, [openPageLoadingCount, loadable]);

  if (!isDisplay) return <></>;

  return (
    <>
      <ProgressBar />

      <RootStyle open={true} {...other}>
        <Box
          sx={{
            width: '200px',
            height: '200px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
          }}
          component={m.div}
          animate={{
            scale: [1, 0.98, 1],
          }}
          transition={{
            duration: 1.5,
            ease: 'easeInOut',
            repeatDelay: 0,
            repeat: Infinity,
          }}
        >
          <CircularProgress size={200} thickness={0.5} />
          <Logo sx={{ width: '70%', height: 'auto', position: 'absolute' }} disabledLink />
        </Box>
      </RootStyle>
    </>
  );
}
