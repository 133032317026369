import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, CardContent, InputAdornment, TextField } from '@mui/material';
import { countries } from '../_mock';
import CustomModal from './CustomModal';
import { DEFAULT_COUNTRY_CODE, DEFAULT_COUNTRY_SELECTIONS } from '../config';
import { checkAndFormatPhoneNumber } from '../utils/formatNumber';
import useLocales from '../hooks/useLocales';
import { StyledInput, StyledTextField } from '../layouts/GlobalStyles';
import Iconify from './Iconify';
import palette from '../theme/palette';

const getCountryCodeObj = (code) => {
  if (!code) return null;
  let countryCode = null;
  if (code) {
    const foundCode = countries.find((x) => x?.code === code);
    if (foundCode) countryCode = foundCode;
  }

  return countryCode;
};

const PhonenumberTextField2 = ({ onChange, value, selectCode = () => {}, selectAreaCode = () => {}, ...others }) => {
  const [callingCode, setCallingCode] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState(value || '');

  const { translate } = useLocales();

  const [openCodeSelect, setOpenCodeSelect] = useState(false);

  // const [openMenu, setOpenMenuActions] = useState(null);

  // const handleOpenMenu = (event) => {
  //   setOpenMenuActions(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setOpenMenuActions(null);
  // };

  const optionalCode = useMemo(
    () => callingCode || getCountryCodeObj(checkAndFormatPhoneNumber(value || '').countryCode) || DEFAULT_COUNTRY_CODE,
    [callingCode, value]
  );

  const optionalValue = useMemo(
    () => textFieldValue || checkAndFormatPhoneNumber(value || '').formattedNumber || '',
    [textFieldValue, value]
  );

  const handleOpenMenu = () => {
    setOpenCodeSelect(true);
  };

  const handleCloseMenu = () => {
    setOpenCodeSelect(false);
  };

  const handleCallingCodeChange = (value) => {
    setCallingCode(value);
    selectCode(value?.code);
    selectAreaCode(value?.phone);
    handleCloseMenu();
    if (optionalValue) {
      onChange(`+${value.phone}${optionalValue}`);
    }
  };

  const handleTextFieldChange = useCallback(
    (event) => {
      // if (isValidPhoneNumber(finalValue, callingCode?.code || DEFAULT_COUNTRY_CODE.code)) {
      //   setTextFieldValue(parsePhoneNumber(finalValue).nationalNumber);
      // } else setTextFieldValue(event.target.value);
      setTextFieldValue(event.target.value);
      const finalValue = event.target.value ? `+${optionalCode?.phone}${event.target.value}` : '';
      onChange(finalValue);
    },
    [optionalCode, onChange]
  );

  return (
    <>
      <StyledTextField
        value={optionalValue}
        sx={{ position: 'relative' }}
        variant="standard"
        InputProps={{
          disableUnderline: true,
          readOnly: others?.readOnly,
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                position: 'relative',
                color: palette.visitor.input.color,
                font: 'inherit',
                fontSize: '0.8rem',
                fontWeight: 900,
                ml: 1,
              }}
            >
              {/* <Button
                variant="text"
                size="small"
                onClick={handleOpenMenu}
                endIcon={<Iconify icon={'mingcute:down-fill'} />}
                sx={{ mr: 0.5, color: palette.visitor.input.color, font: 'inherit', py: 0, fontSize: '0.8rem', }}
                tabIndex={-1}
              >
                +{optionalCode?.phone}
              </Button> */}
              +{optionalCode?.phone}
            </InputAdornment>
          ),
          sx: {
            ...others?.InputPropStyles,
          },
        }}
        {...others}
        onChange={handleTextFieldChange}
      />
      <CustomModal
        fullWidth
        maxWidth="xs"
        title={translate('Select Country Code')}
        open={openCodeSelect}
        onClose={handleCloseMenu}
      >
        <CardContent>
          <Autocomplete
            // disablePortal
            fullWidth
            value={optionalCode}
            options={
              DEFAULT_COUNTRY_SELECTIONS && DEFAULT_COUNTRY_SELECTIONS?.length
                ? countries.filter((x) => DEFAULT_COUNTRY_SELECTIONS.includes(x?.code))
                : countries
            }
            getOptionLabel={(option) => `${option.label} +${option.phone}`}
            onChange={(e, v) => handleCallingCodeChange(v)}
            renderInput={(params) => <TextField fullWidth {...params} label="Country Code" />}
          />
        </CardContent>
      </CustomModal>
    </>
  );
};

PhonenumberTextField2.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  selectCode: PropTypes.func,
  selectAreaCode: PropTypes.func,
};

export default PhonenumberTextField2;
