import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// config
import { defaultLang } from '../config';
//
import enLocales from './en';
import msLocales from './ms';
import myLocales from './my';
import thLocales from './th';
import vnLocales from './vn';
import ptLocales from './pt';
import zhHantLocales from './zhHant';
import id from './id';

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      pt: { translations: ptLocales },
      ms: { translations: msLocales },
      my: { translations: myLocales },
      th: { translations: thLocales },
      vn: { translations: vnLocales },
      zhHant: { translations: zhHantLocales },
      id: { translations: id },
    },
    lng: localStorage.getItem('i18nextLng') || process.env.REACT_APP_LOCALE,
    fallbackLng: process.env.REACT_APP_LOCALE,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
