import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, InputBase, Snackbar, Stack, Typography, styled } from '@mui/material';
// import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import NewButton from '../../../../components/NewButton';
import useLocales from '../../../../hooks/useLocales';

import useAuth from '../../../../hooks/useAuth';
import { useDispatch } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';
import palette from '../../../../theme/palette';
import { InputContainer, StyledInput, ThemeButton } from '../../../GlobalStyles';

const Title = styled(Typography)(({ theme }) => ({
  // fontSize: '0.25rem !important',
  fontWeight: 600,
}));

const StyledIconify = styled(Iconify)(({ theme }) => ({
  height: 24,
  width: 24,
  color: palette.visitor.icon,
}));

const ForgotPasswordText = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontFamily: 'sans-serif',
  fontWeight: '900',
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
  margin: '8px 0px 0px',
  textAlign: 'right',
  lineHeight: '16.94px',
  marginBottom: '10px',
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontFamily: 'sans-serif',
  fontWeight: 900,
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

const LoginButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  marginBottom: 8,
  background: palette.visitor.login.buttonBg,
  border: `2px solid ${palette.visitor.login.border}`,
  color: palette.visitor.login.color,
}));

const LoginDirectContainer = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  borderBottom: '1px solid #fff',
  lineHeight: '0.1em',
  margin: '10px 0 20px',
  marginTop: '20px',
}));

const LoginDirectText = styled('span')(({ theme }) => ({
  background: '#252631',
  padding: '0 10px',
  fontWeight: 700,
  fontSize: '14px',
}));

const LoginImages = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const _loginForm = {
  userUsername: '',
  userPassword: '',
  captcha: '',
};

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: '1.5px solid black', borderBottom: 'none', height: 1, ...style }} />
);

const ErrorText = ({ children }) => <span style={{ color: 'red' }}>{children}</span>;

const Login = ({ setOpenForgotPasswordDialog, setActiveTab }) => {
  const { login } = useAuth();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [loginForm, setLoginForm] = useState(_loginForm);
  const [formError, setFormError] = useState({});
  const [isEmail, setIsEmail] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
    type: 'success',
  });
  const [validationErrors, setValidationErrors] = useState(null);

  // useEffect(() => {
  //   setTimeout(() => {
  //     loadCaptchaEnginge(4);
  //   }, 1000);
  // }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [loginForm]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setLoginForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async () => {
    if (!isValid()) return;

    setIsLoading(true);

    const requestData = {
      'user-username': loginForm?.userUsername.trim(),
      'user-password': loginForm?.userPassword.trim(),
    };

    try {
      await login(requestData);

      // close login/register dialog
      dispatch(openLoginSignup({ open: false, isLogin: true }));
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  const isValid = () => {
    let valid = true;
    const _error = {};

    if (loginForm?.userUsername.trim().length < 3) {
      valid = false;
      _error.userUsername = translate('invalid_x', { x: translate('username') });
    } else {
      _error.userUsername = '';
    }

    if (loginForm?.userPassword.trim().length < 5) {
      valid = false;
      _error.userPassword = translate('invalid_x', { x: translate('password') });
    } else {
      _error.userPassword = '';
    }

    // if (validateCaptcha(loginForm?.captcha?.trim(), false) === true) {
    //   _error.userCaptcha = '';
    // } else {
    //   valid = false;
    //   _error.userCaptcha = translate('captcha_not_match');
    // }

    setFormError((prevState) => _error);

    return valid;
  };

  const LinkText = ({ children }) => (
    <span
      role="presentation"
      onMouseDown={() => dispatch(openLoginSignup({ open: true, isLogin: false }))}
      style={{ cursor: 'pointer', fontWeight: 900, color: palette.visitor.link }}
    >
      {children}
    </span>
  );

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '10px' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '0.6rem' }}>
        <Title>{translate('login')}</Title>
        <NewButton
          variant="contained"
          onClick={() => dispatch(openLoginSignup({ isLogin: true, open: false }))}
          label={translate('back')}
        />
      </Box>

      {/* <Box sx={{ borderRadius: '0.4rem', backgroundColor: palette.visitor.input.bg, display: 'flex', mb: '0.6rem' }}>
        <ThemeButton
          disableFocusRipple
          onClick={() => setIsEmail(false)}
          sx={{
            background: `rgba(255,255,255, ${isEmail ? '0' : '0.1'})`,
            width: '50%',
            py: '0.6rem',
            '&:hover': { background: `rgba(255,255,255, ${isEmail ? '0' : '0.1'})` },
          }}
        >
          {translate('Mobile Number')}
        </ThemeButton>
        <ThemeButton
          disableFocusRipple
          onClick={() => setIsEmail(true)}
          sx={{
            background: `rgba(255,255,255, ${isEmail ? '0.1' : '0'})`,
            width: '50%',
            py: '0.6rem',
            '&:hover': { background: `rgba(255,255,255, ${isEmail ? '0.1' : '0'})` },
          }}
        >
          {translate('Email')}
        </ThemeButton>
      </Box> */}
      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledInput
            name="userUsername"
            value={loginForm?.userUsername}
            placeholder={translate('username_placeholder')}
            onChange={(e) => handleFormChange(e)}
          />
        </InputContainer>
        {formError?.userUsername && <ErrorText>{formError?.userUsername}</ErrorText>}
      </Box>

      <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row" spacing={0}>
          <StyledInput
            type={showPassword ? 'text' : 'password'}
            name="userPassword"
            value={loginForm?.userPassword}
            placeholder={`${translate('login')} ${translate('password')}`}
            onChange={(e) => handleFormChange(e)}
          />
          <StyledIconify
            icon={showPassword ? 'ph:eye-light' : 'ph:eye-closed-light'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowPassword((prevState) => !prevState)}
          />
        </InputContainer>
        {formError?.userPassword && <ErrorText>{formError?.userPassword}</ErrorText>}
      </Box>

      {/* <Box sx={{ mb: '10px' }}>
        <InputContainer direction="row">
          <StyledInput
            name="captcha"
            placeholder={translate('captcha')}
            value={loginForm?.captcha}
            onChange={(e) => handleFormChange(e)}
          />
          <Box component={'span'} sx={{ height: '30px', display: 'flex', alignItems: 'center' }}>
            <LoadCanvasTemplateNoReload />
          </Box>
        </InputContainer>
        {formError?.userCaptcha && <ErrorText>{formError?.userCaptcha}</ErrorText>}
      </Box> */}

      <ForgotPasswordText onClick={() => setOpenForgotPasswordDialog(true)}>
        {translate('forgot_password')}
      </ForgotPasswordText>

      <NewButton
        onClick={() => onSubmit()}
        fullWidth
        variant="contained"
        loading={isLoading}
        label={translate('login')}
      />

      {/* <FooterText>
        {translate('minor_note')} <span style={{ textDecoration: 'underline' }}>{translate('terms_of_service')}</span>
      </FooterText> */}

      <Box
        sx={{
          mt: 3,
          fontSize: '14px',
          fontWeight: 700,
          textAlign: 'center',
        }}
      >
        {translate('new_to_x')}? <LinkText>{translate('create_an_account')}</LinkText>
      </Box>
      {/* 
        <LoginDirectContainer>
          <LoginDirectText>
              Or log in directly with
          </LoginDirectText>
        </LoginDirectContainer>
        <LoginImages>
          <img src={require('../../../../assets/google-icon.png')} alt="Loading" style={{ width: '21px', flexBasis: '15%', padding: '18px 18px' }} />
          <img src={require('../../../../assets/telegram-icon.png')} alt="Loading" style={{ width: '21px', flexBasis: '15%', padding: '18px 18px' }} />
        </LoginImages>
      */}
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Login.propTypes = {
  setOpenForgotPasswordDialog: PropTypes.func,
  setActiveTab: PropTypes.func,
};

export default Login;
