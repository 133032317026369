import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

import palette from '../theme/palette';

// ----------------------------------------------------------------------
const StyledButton = styled(LoadingButton)(({ isAlternate }) => ({
    border: 'none',
    outline: 'none',
    position: 'relative',
    zIndex: 1,
    borderRadius: '20px',
    boxShadow: 'none',
    cursor: 'pointer',
    background: isAlternate ? 'linear-gradient(90deg, #FFA8A8, #FEAAAA, #F01A1A, #FFB3B3, #FEADAD, #F11A1A, #FFB3B3, #FFA8A8)' : 'linear-gradient(90deg, #FFFAA8, #FEF7AA, #F0C91A, #FFFAB3, #FEF8AD, #F1CA1A, #FFFAB3, #FFFAA8)',
        
    '&::before': {
        content: '""',
        position: 'absolute',
        left: '1px',
        right: '1px',
        top: '1px',
        bottom: '1px',
        borderRadius: '20px',
        color: palette.visitor.login.color,
        background: palette.visitor.login.buttonBg,
        zIndex: -1,
        transition: '200ms',
      },
    '&::after': {
        content: 'attr(data)',
        backgroundClip: 'text',
        color: palette.visitor.login.color,
        transition: '200ms',
      },
      /* '&:hover': {
        background: 'linear-gradient(90deg, #FFA8A8, #FEAAAA, #F01A1A, #FFB3B3, #FEADAD, #F11A1A, #FFB3B3, #FFA8A8)',
      } */
  }));

const NewButton = ({variant, label, onClick, fullWidth, loading, isLarge = false, isAlternate = false}) => {

  return (
    <StyledButton
        fullWidth={fullWidth}
        loading={loading}
        variant={variant}
        onClick={onClick}
        data={label}
        sx={isLarge ? { width: '40%', fontSize: '1rem', padding: .8, borderRadius: '20px'} : { fontSize: '.8rem', padding: .8 }}
        isAlternate={isAlternate}
    />
  );
};

export default NewButton;
