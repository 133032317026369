// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const id = {
  game_name: `Nama Game`,
  game: `Permainan`,
  games: `Permainan`,
  sport: `Olahraga`,
  center: `Pusat`,
  mines: `MINES`,
  crash: `CRASH`,
  lucky_colour: `WARNA KEBERUNTUNGAN`,
  dice: `DADU`,
  limbo: `LIMBO`,
  lucky_gift: `HADIAH KEBERUNTUNGAN`,
  all_games: `Semua Permainan`,
  game_history: `Riwayat Permainan`,
  coupon_code: `Kode Kupon`,
  telegram: `Telegram`,
  customer_service: `Layanan Pelanggan`,
  player: `Pemain`,
  winner: `Pemenang`,
  all_bets: `Semua Taruhan`,
  my_bets: `Taruhan Saya`,
  name: `Nama`,
  time: `Waktu`,
  bet_amount: `Jumlah Taruhan`,
  prediction: `Prediksi`,
  payout: `Pembayaran`,
  login: `Masuk`,
  register: `Daftar`,
  username_placeholder: `Nama Pengguna`,
  please_input_your_x: `Silakan Masukkan {{x}} Anda`,
  captcha: `CAPTCHA`,
  forgot_password: `Lupa Kata Sandi`,
  minor_note: `Untuk mengunjungi situs ini, pastikan Anda berusia di atas 18 tahun dan setuju`,
  terms_of_service: `Syarat dan Ketentuan`,
  by_signing_in: 'Dengan Masuk atau Mendaftar, Anda setuju dengan',
  dont_have_account: `Belum punya akun?`,
  sign_up: `Daftar`,
  username: `Nama Pengguna`,
  email: `Email`,
  full_name: `Nama Lengkap`,
  confirm_18: `Saya mengonfirmasi bahwa saya berusia 18 tahun dan telah membaca`,
  already_have_account: `Sudah punya akun?`,
  share: `Bagikan`,
  deposit: `Deposit`,
  bonus: `Bonus`,
  app: `Aplikasi`,
  load_more: `Muat Lebih Banyak`,
  more: `Lebih`,
  details: `Detail`,
  helps: `BANTUAN`,
  kyc_policy: `Kebijakan KYC`,
  privacy_policy: `Kebijakan Privasi`,
  responsible_gaming: `Permainan Bertanggung Jawab`,
  terms_and_condition: `Syarat dan Ketentuan`,
  faq: `FAQ`,
  about_us: `TENTANG KAMI`,
  amount: `Jumlah`,
  extra: `Ekstra`,
  deposit_bonus: `Bonus Deposit`,
  do_not_participate: `Tidak ikut serta dalam promosi`,
  payment_problem: `Masalah Pembayaran`,
  click_for_help: `Klik untuk bantuan`,
  deposit_notes: `Catatan Deposit`,
  available_balance: `Saldo Tersedia`,
  withdraw: `Tarik`,
  withdrawal_notes: `Catatan Penarikan`,
  withdrawal_note1: `Jumlah penarikan saat ini`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Biaya Penarikan:`,
  min: `Min:`,
  max: `Maks:`,
  opening_times: `Jam Buka:`,
  withdrawal_note3: `1. Waktu penarikan tercepat dalam 10 menit`,
  withdrawal_note4: `2. Pastikan informasi penarikan Anda akurat, jika informasi penarikan salah, mungkin akan menyebabkan dana Anda hilang.`,
  withdrawal_note5: `3. Jangan ragu untuk menghubungi kami jika Anda memiliki pertanyaan.`,
  notifications: `Notifikasi`,
  invalid_x: `{{x}} tidak valid`,
  captcha_not_match: `Captcha Tidak Cocok`,
  invalid_x_must_be_atleast_y_characters: `{{x}} tidak valid, harus minimal {{y}} karakter`,
  x_is_required: `{{x}} diperlukan`,
  phone_number: 'Nomor Telepon',
  phone_num_x_max: `Nomor Telepon maksimal {{x}} digit`,
  email_sent: `Email terkirim, silakan periksa email Anda.`,
  enter_valid_email: `Silakan masukkan email yang valid.`,
  confirm: `Konfirmasi`,
  password_atleast_6_characters: `Kata sandi harus minimal 6 karakter.`,
  password_reset_success: `Reset kata sandi berhasil!.`,
  new_password: `Kata Sandi Baru`,
  bank: 'Bank',
  bank_transfer: `Transfer Bank`,
  bank_name: `Nama Bank`,
  bank_branch: `Cabang Bank`,
  pay_id: `ID Pembayaran`,
  account_name: `Nama Akun`,
  account_number: `Nomor Akun`,
  drop_or_select: `Jatuhkan atau Pilih file`,
  drop_files_here: `Jatuhkan file di sini`,
  transfer_to: `Transfer Ke`,
  upload_receipt: `Unggah Bukti`,
  select_bonus: `Pilih Bonus`,
  no_promotion: `Tidak ada Promosi`,
  please_upload_receipt: `Silakan unggah bukti`,
  bank_account: `Rekening Bank`,
  add_account: `Tambah Akun`,
  account_holder_name: `Nama Pemegang Akun`,
  name_of_the_account_holder: `Nama Pemegang Akun`,
  fill_in_bank_account: `Isi Rekening Bank`,
  withdraw_information: `Informasi Penarikan`,
  note: `Catatan`,
  amount_withdrawn: `Jumlah yang ditarik`,
  amount_no_exceed_balance: `Jumlah Penarikan tidak boleh melebihi saldo yang tersedia`,
  minimum_withdraw_amount_is_x: `Jumlah Penarikan Minimum adalah {{x}}`,
  transaction_history: `Riwayat Transaksi`,
  date_from: `Tanggal Dari`,
  date_to: `Tanggal Hingga`,
  account_name_must_be_4: `Nama Akun harus lebih dari 4 karakter`,
  account_number_must_be_x: `Nomor akun harus 10-14 karakter`,
  minimum_length_is_x: `Panjang minimum adalah {{x}}`,
  maximum_length_is_x: `Panjang maksimum adalah {{x}}`,
  payment_method: `Metode Pembayaran`,
  mode: `Mode`,
  no_bank_account: `Anda tidak memiliki akun untuk menarik dana, Tambah sekarang`,
  please_select_bank: `Silakan pilih Rekening Bank`,

  bet_history: `Riwayat Taruhan`,
  game_provider: `Penyedia Permainan`,
  bet_count: `Jumlah Taruhan`,
  win_loss: `Menang/Kalah`,
  rebate: `Rabate`,
  turnover: `Omset`,
  game_category: `Kategori Permainan`,

  you_have_x_unread_messages: `Anda memiliki {{x}} pesan yang belum dibaca`,
  deposit_amount_between_x_and_y: `Jumlah deposit harus antara {{x}} dan {{y}}`,
  minimum_amount_is_x_y: `Jumlah minimum adalah {{x}}{{y}}`,

  all: `Semua`,
  title: `Judul`,
  date: `Tanggal`,
  type: `Tipe`,
  status: `Status`,
  remark: `Keterangan`,
  category: `Kategori`,
  please_select_x: `Silakan pilih {{x}}`,
  save: `Simpan`,

  change_password: `Ubah Kata Sandi`,
  current_password: `Kata Sandi Saat Ini`,
  confirm_password: `Konfirmasi Kata Sandi Baru`,
  submit: `Kirim`,
  password_changed: `Kata Sandi Diubah`,
  x_must_be_y_characters: `{{x}} harus minimal {{y}} karakter`,
  reset_password: `Atur Ulang Kata Sandi`,
  casino: `Kasino`,
  slots: `Slots`,
  live_casino: `Kasino Langsung`,
  fishing: `Memancing`,
  sports: `Olahraga`,
  cards: `Kartu`,
  clear: `Bersihkan`,
  search: `Cari`,
  new: `Baru`,
  logout: `Keluar`,
  choose_date: `Pilih Tanggal`,
  select_both_date: `Pilih Tanggal Dari dan Hingga`,
  from: `Dari`,
  to: `Ke`,
  apply: `Terapkan`,
  promotions: `Promosi`,
  learn_more: `PELAJARI LEBIH LANJUT`,
  password_doesnt_match: `Kata sandi tidak cocok`,
  cancel: `Batal`,
  confirm_to_pay: `Konfirmasi Untuk Membayar`,
  deposit_amount: `Jumlah Deposit`,
  loading_please_wait: `Memuat, Silakan Tunggu`,
  account: `Akun`,
  profile: `Profil`,
  bank_list: `Daftar Bank`,
  in_maintenance: `Dalam Pemeliharaan`,
  birthday: `Ulang Tahun`,
  birthday_bonus: `Masukkan tanggal ulang tahun Anda untuk bonus! 🎉🎁`,
  referral_code: `Kode Referensi`,
  referral: `Referensi`,
  my_referral: `Referensi Saya`,
  my_claim: `Klaim Saya`,
  claim_profit: `KLAIM KEUNTUNGAN`,
  available_profit: `Keuntungan Tersedia`,
  claimed_profit: `Keuntungan Diklaim`,
  all_time_profit: `Keuntungan Sepanjang Waktu`,
  referral_note_on_x: `Dapatkan keuntungan setiap kali teman Anda bermain di {{x}}`,
  tier: `Tingkat`,
  commission: `Komisi`,
  referred_member: `Anggota yang Direferensikan`,
  profit_earned: `Keuntungan yang Diperoleh`,
  account_information: `Informasi Akun`,
  invite_friends: `Undang Teman`,
  how_it_work: `Bagaimana cara kerjanya?`,
  invite_note: `Dapatkan bonus referensi eksklusif saat teman Anda mendaftar menggunakan kode referensi Anda!`,
  share_via: `Bagikan melalui`,
  referral_link: `Tautan Referensi`,
  total_profit: `Total Keuntungan`,
  copied: `Disalin`,
  nothing_to_claim: `Tidak ada yang bisa diklaim`,
  claim_history: `Riwayat Klaim`,
  downline: `Downline Saya`,
  last_x_digit_bank_ref_number: `Digit terakhir {{x}} dari nomor Ref Bank`,
  bank_ref_number_validation_x: `Nomor Ref Bank diperlukan dan harus {{x}} karakter panjangnya`,
  sort: 'Urutkan',
  login_directly: `Masuk langsung dengan`,
  our_game_provides: `PENYEDIA PERMAINAN KAMI`,
  refer_and_reward: `Referensi & Hadiah`,
  linked_successfully_x: `Tertaut berhasil dengan {{x}}! Silakan daftar akun Anda untuk pertama kali`,
  more_x_of_y: `Lebih {{x}}/{{y}}`,
  default: `Default`,
  promotion: `Promosi`,
  daily_mission: 'Misi Harian',
  agent: 'Agen',
  contact_us: 'Hubungi Kami',
  download: 'Unduh',
  cash_claim: 'Klaim Tunai',
  demo: 'Demo',
  favorites: 'Favorit',
  popular: 'Populer',
  claim_bonus: `Klaim Bonus`,
  insufficient_balance: `Saldo Tidak Cukup`,
  currently_displaying: 'Saat ini menampilkan {{x}} {{y}} permainan dari {{z}}',
  all_x: 'Semua {{x}}',
  no_results: 'Tidak Ada Hasil',
  search_games: 'Cari Permainan',
  please_scan_using_your: `Silakan pindai menggunakan`,
  vip_club: `Klub VIP`,
  vip_level: `Level VIP`,
  bonus_direct: `BONUS LANGSUNG`,
  rebate_level: `Level Rabate`,
  vip_header: `Semua taruhan yang ditempatkan pada Mesin Slot, Permainan Papan dan Kartu, Memancing, Video, Olahraga dan Permainan Lotre di AHMAD77 akan diakumulasikan secara permanen. Taruhan yang valid = Pendapatan Permanen dan Keberuntungan Uang!`,
  vip_example: `
  Contoh 1: Anggota yang mencapai akumulasi deposit 1 Juta, mencapai Level VIP 2, menerima bonus sebesar R$210, dan Rabate 3,50%. Ketika mereka mencapai akumulasi deposit 6 Juta, mereka mencapai Level VIP 3 dan menerima bonus sebesar R$800, dengan Rabate 4%`,
  activity_rules: `Aturan Aktivitas:`,
  activity_rules_1: `1. Diperlukan semua bonus memiliki x3 Winover untuk meminta Penarikan`,
  activity_rules_2: `2. Silakan klaim Bonus Rabate dalam batas waktu, jika terlambat, akan dianggap sebagai pengabaian;`,
  activity_rules_3: `3. Partisipasi anggota dalam aktivitas secara otomatis dihitung oleh sistem, jika ada keberatan, hasil penyelidikan AHMAD77 akan berlaku;`,
  activity_rules_4: `4. Jika Anda lupa Akun/Password anggota Anda, silakan hubungi "Layanan Pelanggan Online 24 jam" untuk membantu Anda memulihkan informasi akun Anda;`,
  activity_rules_5: `5. AHMAD77 berhak untuk mengubah, menghentikan atau membatalkan promosi ini kapan saja.`,
  activity_rules_6: `6. Jangan menyimpan permainan gratis, Beli permainan gratis`,
  activity_rules_7: `7. Tidak dapat digabungkan dengan bonus apapun`,
  activity_rules_8: `8. Penarikan maksimum per Deposit adalah R$100,000`,
  event_instructions: 'Instruksi Acara',
  agent_p1: 'Bagikan tautan unik Anda di aplikasi dan media sosial',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube, dan lainnya, segera undang semua orang, anggota yang mendaftar melalui tautan eksklusif Anda akan menjadi agen langsung Anda. Tapi perhatikan karena anggota baru yang Anda undang harus asli dan valid.',
  agent_step1:
    'Dapatkan undangan teman, deposit pertama berhasil, Anda bisa memenangkan bonus sebesar R$5. Misalnya, jika Anda mengundang 10 orang untuk deposit pertama, Anda bisa mendapatkan R$50. Semakin banyak orang yang Anda undang, semakin banyak bonus yang akan Anda terima. Anda dapat melihat riwayat dan pendaftaran Anda di halaman promosi.',
  agent_step2:
    'Jika tamu Anda bertaruh R$1 atau lebih, Anda bisa langsung menikmati komisi tinggi! Anda bisa mendapatkan hingga 5% komisi seumur hidup, dan undangan seumur hidup! Sistem akan menyelesaikan komisi hari sebelumnya pada pukul 7 pagi hari berikutnya.',
  agent_step2_1: 'Cara mendapatkan: Login - Promosi undangan - Kumpulkan komisi',
  agent_table1: 'Tarif komisi taruhan untuk slot, memancing dan blockchain adalah sebagai berikut:',
  agent_table1_1: 'Pelanggan topup 20 bertaruh 200',
  person: 'orang',
  agent_table2_title1: 'Taruhan anggota',
  agent_table2_title2: 'Hanya Permainan Slot',
  agent_table2_example:
    'Contoh: Anda memiliki 100 anggota, dan total nilai nyata taruhan diperkirakan 1 juta real per hari. Komisi yang Anda terima hari itu adalah: 1.000.000 x 1,20% = 12.000 real. Pendapatan bulanan sebesar 100.000 sangat mudah dicapai.',
  reminder: 'Pengingat',
  agent_reminder:
    'Akun anggota dengan IP yang sama, perangkat yang sama, nama, kartu bank, dan informasi lainnya hanya dapat berpartisipasi sekali, dan siapa pun yang melanggar aturan tidak akan mendapat manfaat dari bonus ini. atau grup akan disita dan akun akan dibekukan secara permanen',
  agent_step3_1:
    'Partisipasi anggota dalam aktivitas secara otomatis dihitung oleh sistem, jika ada keberatan, hasil analisis yang dilakukan oleh AHMAD77 akan berlaku',
  agent_step3_2:
    'Jika Anda lupa akun/password anggota Anda, silakan hubungi tim layanan pelanggan 24 jam kami untuk membantu Anda memulihkan informasi akun Anda',
  agent_step3_3: 'AHMAD77 berhak mengubah, menghentikan atau membatalkan promosi kapan saja',
  agent_step3_4: 'Agen yang menerima bonus komisi adalah dalam bentuk uang tunai atau kredit dalam dompet',
  agent_step3_5: 'Jangan menyimpan permainan gratis, Beli permainan gratis',
  agent_step3_6: 'Tidak dapat digabungkan dengan bonus dan deposit apapun',
  agent_step3_7: 'Hanya tersedia untuk permainan Slot',
  promo_concluded: 'Promo telah berakhir! Periksa lagi besok!',
  can_only_claim: 'Hanya bisa klaim bonus antara {{x}} hingga {{y}} Waktu Brasil',
  please_login: 'Silakan Masuk terlebih dahulu untuk mengklaim bonus!',
  back_to_home: 'Kembali ke Beranda',
  bonus_claimed: 'Bonus Sudah Diklaim!',
  terms_n_conditions: `SYARAT DAN KETENTUAN`,
  the_activity_x_in: `Aktivitas {{x}} Dalam`,
  starts: `Mulai`,
  ends: `Berakhir`,
  list_of_winners: `DAFTAR PEMENANG`,
  days: `Hari`,
  hours: `Jam`,
  minutes: `Menit`,
  seconds: `Detik`,
  total_amount_received: `Jumlah Total Diterima`,
  claim: `Klaim`,
  security: `Keamanan`,
  certification: `Sertifikasi`,
  menu: `Menu`,
  win: 'Menang',
  contact_us_text: `Hubungi Kami`,
  live_chat: `Obrolan Langsung`,
  chat_now: `Obrolan Sekarang`,
  gaming_license: `Lisensi Permainan`,
  license_text_x: `{{x}} adalah entitas yang dilisensikan dan diatur oleh pemerintah Curacao dan beroperasi secara sah di bawah Lisensi Induk Penyedia Layanan Permainan, N.V #365/JAZ`,
  payment_draft: 'Jika Anda yakin Anda sudah membayar, harap tunggu sebentar karena pembayaran mungkin masih diproses',
  show_all: `Tampilkan Semua`,
  player_reviews: `Ulasan Pemain`,
  new_to_x: `Baru di AHMAD77`,
  create_an_account: `Buat Akun`,
  back: `Kembali`,
  password: `Kata Sandi`,
  optional: `Opsional`,
  balance: `Saldo`,
  withdraw_amount: `Jumlah Penarikan`,
  referral1_title_x: `Dapatkan Bonus $1500 dan Komisi Hingga 30% Per Rujukan`,
  referral1_desc: `Bagikan hingga 50% komisi dengan teman, mendorong mereka untuk bermain lebih banyak game!`,

  referral2_title_x: `Dapatkan Bonus $1500 Per Rujukan`,
  referral2_desc: `Setiap pengguna yang dirujuk memberi Anda bonus $1500! Teman-teman Anda juga akan menerima bonus $200 masing-masing.`,

  referral3_title: `Dapatkan Komisi Hingga 30%`,
  referral3_desc: `Undang teman Anda dan dapatkan uang saat mereka bersenang-senang! Dapatkan hingga 30% dari keuntungan platform dari taruhan mereka!`,

  referral4_title: `Program Afiliasi Eksklusif`,
  referral4_desc: `Apakah Anda memiliki audiens dan pengikut yang besar? Jadilah mitra kami, dengan keuntungan RevShare hingga 60%. Dapatkan persentase dari pendapatan bersih dari pemain yang dirujuk. Bergabunglah dengan kami dan nikmati kesuksesan bersama!`,
  become_a_partner: `Jadilah Mitra`,
  total_referrals: `Total Rujukan`,
  total_paid: `Total Dibayar`,
  refer_now: `Rujuk Sekarang`,
  live_rewards: `Hadiah Langsung`,
  lobby: `Lobi`,
  level: `Tingkat`,
  home: `Beranda`,
  instant_game: `Permainan Instan`,
  apply_now: `Daftar Sekarang`,
  more_info: `Info Lebih Lanjut`,
  card_game: `Permainan Kartu`,
  e_sports: `E-Sports`,
};

export default id;
