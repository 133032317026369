// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const pt = {
  game: `Jogo`,
  games: `Jogos`,
  sport: `Esporte`,
  center: `Centro`,
  mines: `MINAS`,
  crash: `COLIDIR`,
  lucky_colour: `COR DA SORTE`,
  dice: `DICE`,
  limbo: `LIMBO`,
  lucky_gift: `PRESENTE DE SORTE`,
  all_games: `Todos os jogos`,
  game_history: `História do jogo`,
  coupon_code: `Coupon Code`,
  telegram: `Telegrama`,
  customer_service: `Atendimento ao Cliente`,
  player: `Player`,
  winner: `Ganhador`,
  all_bets: `Todas as apostas`,
  my_bets: `Minhas apostas`,
  name: `Nome`,
  time: `Tempo`,
  bet_amount: `Valor da aposta`,
  prediction: `Predição`,
  payout: `Pagamento`,
  login: `Entrar`,
  register: `Registro`,
  username_placeholder: `Nome de usuário`,
  please_input_your_x: `Insira seu {{x}}`,
  captcha: `CAPTCHA`,
  forgot_password: `Esqueceu sua senha`,
  minor_note: `Para visitar este site, certifique-se de ter mais de 18 anos e concordar`,
  terms_of_service: `Terms of Service`,
  by_signing_in: 'Ao fazer login ou se cadastrar, você concorda com nossa',
  dont_have_account: `Não tem uma conta?`,
  sign_up: `Registro`,
  username: `Nome de Usuário`,
  email: `E-mail`,
  full_name: `Nome completo`,
  confirm_18: `Confirmo que tenho 18 anos e li o`,
  already_have_account: `já tem uma conta?`,
  share: `Compartilhar`,
  deposit: `Depósito`,
  bonus: `Bônus`,
  app: `App`,
  load_more: `Carregar mais`,
  details: `Detalhes`,
  helps: `AJUDA`,
  kyc_policy: `Política KYC`,
  privacy_policy: `política de Privacidade`,
  responsible_gaming: `Jogo responsável`,
  terms_and_condition: `Termos e Condições`,
  faq: `FAQ`,
  about_us: `SOBRE NÓS`,
  amount: `Quantia`,
  extra: `Extra`,
  deposit_bonus: `Bônus de Depósito`,
  do_not_participate: `Não participe de promoções`,
  payment_problem: `Problema de pagamento`,
  click_for_help: `Clique para obter ajuda`,
  deposit_notes: `Notas de Depósito`,
  available_balance: `Saldo disponível`,
  withdraw: `Retirar`,
  withdrawal_notes: `Notas de retirada`,
  withdrawal_note1: `Número de saques atuais`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Taxa de retirada:`,
  min: `Min:`,
  max: `Max:`,
  opening_times: `Horario de funcionamento:`,
  withdrawal_note3: `1. Tempo de retirada mais rápido em 10 minutos`,
  withdrawal_note4: `2.Certifique-se de que suas informações de retirada estejam corretas. Se as informações de retirada estiverem erradas, isso poderá causar a perda de seus fundos.`,
  withdrawal_note5: `3. sinta-se à vontade para entrar em contato conosco se tiver alguma dúvida.`,
  notifications: `Notificações`,
  invalid_x: `{{x}} inválido`,
  captcha_not_match: `Captcha não corresponde`,
  invalid_x_must_be_atleast_y_characters: `{{x}} inválido, deve ter pelo menos {{y}} caracteres`,
  x_is_required: `{{x}} é obrigatório`,
  phone_number: 'Número de telefone',
  phone_num_x_max: `Máximo de {{x}} dígitos do número de telefone`,
  email_sent: `E-mail enviado, verifique seu e-mail.`,
  enter_valid_email: `Please enter a valid email.`,
  confirm: `Confirme`,
  password_atleast_6_characters: `A senha deve ter pelo menos 6 caracteres.`,
  password_reset_success: `Sucesso na redefinição de senha!`,
  new_password: `Nova Senha`,
  bank: 'Banco',
  bank_transfer: `Transferência bancária`,
  bank_name: `Nome do banco`,
  account_name: `Nome da conta`,
  account_number: `Número de conta`,
  drop_or_select: `Soltar ou selecionar arquivo`,
  drop_files_here: `Solte os arquivos aqui`,
  transfer_to: `Transferir para`,
  upload_receipt: `Carregar recibo`,
  select_bonus: `Selecione Bônus`,
  no_promotion: `Sem promoção`,
  please_upload_receipt: `Carregue o recibo`,
  bank_account: `Conta bancária`,
  add_account: `Adicionar Conta`,
  account_holder_name: `nome do titular da conta`,
  name_of_the_account_holder: `Nome do titular da conta`,
  fill_in_bank_account: `Preencha a conta bancária`,
  withdraw_information: `Retirar informações`,
  note: `Observação`,
  amount_withdrawn: `Valor retirado`,
  amount_no_exceed_balance: `O valor da retirada não deve exceder o saldo disponível`,
  minimum_withdraw_amount_is_x: `O valor mínimo de retirada é {{x}}`,
  transaction_history: `Histórico de transações`,
  date_from: `Data de`,
  date_to: `Data para`,
  account_name_must_be_4: `O nome da conta deve ter mais de 4 de comprimento`,
  account_number_must_be_x: `O número da conta deve ter de 10 a 14 anos`,
  minimum_length_is_x: `O comprimento mínimo é {{x}}`,
  maximum_length_is_x: `O comprimento máximo é {{x}}`,
  payment_method: `Forma de pagamento`,
  mode: `Modo`,
  no_bank_account: `Você não tem nenhuma conta para fazer saques, adicione agora`,
  please_select_bank: `Selecione uma conta bancária`,

  bet_history: `Histórico de apostas`,
  game_provider: `Provedor de jogos`,
  bet_count: `Contagem de apostas`,
  win_loss: `Vitória/Perda`,
  rebate: `Desconto`,
  turnover: `Volume de negócios`,
  game_category: `Categoria de jogo`,

  you_have_x_unread_messages: `Você tem {{x}} mensagens não lidas`,
  deposit_amount_between_x_and_y: `O valor do depósito deve estar entre {{x}} e {{y}}`,
  minimum_amount_is_x_y: `O valor mínimo é {{x}}{{y}}`,

  // all
  all: `Tudos`,
  title: `Título`,
  date: `Data`,
  type: `Tipo`,
  status: `Status`,
  remark: `Observação`,
  category: `Categoria`,
  please_select_x: `Selecione {{x}}`,
  save: `Salvar`,

  change_password: `Alterar a senha`,
  current_password: `Senha atual`,
  confirm_password: `Confirme a nova senha`,
  submit: `Enviar`,
  password_changed: `Senha alterada`,
  x_must_be_y_characters: `{{x}} deve ter pelo menos {{y}} caracteres`,
  reset_password: `Reset Password`,
  casino: `Cassino`,
  slots: `Slots`,
  live_casino: `Cassino ao vivo`,
  fishing: `pescaria`,
  sports: `Sports`,
  cards: `Cartões`,
  clear: `Remover`,
  search: `Procurar`,
  new: `Renovado`,
  logout: `Sair`,
  choose_date: `Escolha a data`,
  select_both_date: `Selecione a data de e até`,
  from: `De`,
  to: `Para`,
  apply: `Aplicar`,
  promotions: `Promoções`,
  learn_more: `SABER MAIS`,
  password_doesnt_match: `A senha não corresponde`,
  cancel: `Cancelar`,
  confirm_to_pay: `Confirmar para pagar`,
  deposit_amount: `Valor do depósito`,
  loading_please_wait: `Carregando, por favor espere`,
  account: `Conta`,
  profile: `Perfil`,
  bank_list: `Lista de Bancos`,
  in_maintenance: `Em manutenção`,
  birthday: `Aniversário`,
  birthday_bonus: `Digite seu aniversário para ganhar um bônus! 🎉🎁`,
  referral_code: `Código de Referencia`,
  referral: `Referência`,
  my_referral: `Minha indicação`,
  my_claim: `Minha reivindicação`,
  claim_profit: `REIVINDICAR LUCRO`,
  available_profit: `Lucro disponível`,
  claimed_profit: `Lucro reivindicado`,
  all_time_profit: `Lucro o tempo todo`,
  referral_note_on_x: `Ganhe lucro sempre que seus amigos jogarem no {{x}}`,
  tier: `Nível`,
  commission: `Comissão`,
  referred_member: `Membro Indicado`,
  profit_earned: `Lucro obtido`,
  account_information: `Informação da conta`,
  invite_friends: `Convide amigos`,
  how_it_work: `Como isso funciona?`,
  invite_note: `Receba bônus de indicação exclusivos quando seu amigo se inscrever usando seu código de indicação!`,
  share_via: `Compartilhar via`,
  referral_link: `Link de referência`,
  total_profit: `Lucro total`,
  copied: `Copiado`,
  nothing_to_claim: `Nada a reclamar`,
  claim_history: `Histórico de reivindicações`,
  downline: `Minha linha descendente`,
  last_x_digit_bank_ref_number: `Último {{x}} dígito do número de referência bancária`,
  bank_ref_number_validation_x: `O número de referência bancária é obrigatório e deve ter {{x}} caracteres`,
  sort: 'Organizar',
  login_directly: `Faça login diretamente com`,
  our_game_provides: `NOSSOS FORNECEDORES DE JOGOS`,
  refer_and_reward: `Indique e recompense`,
  linked_successfully_x: 'Conectado com sucesso com {{x}}! Por favor, registre sua conta pela primeira vez',
  more_x_of_y: `Mais {{x}}/{{y}}`,
  default: `Padrão`,
  promotion: `Promoção`,
  daily_mission: 'Missão Diária',
  agent: 'Agente',
  contact_us: 'Contate-nos',
  download: 'Download',
  cash_claim: 'Reivindicar dinheiro',
  demo: 'Demo',
  favorites: 'Favoritos',
  popular: 'Popular',
  claim_bonus: `Reivindicar bônus`,
  insufficient_balance: `Saldo insuficiente`,
  currently_displaying: 'A exibir {{x}} jogos entre {{y}} {{z}} jogos',
  all_x: 'Tudo {{x}}',
  no_results: 'Nenhum resultado encontrado',
  search_games: 'Procurar jogos',
  please_scan_using_your: `Por favor, digitalize usando seu`,
  vip_level: `Nível VIP`,
  bonus_direct: `BÔNUS DIRETO`,
  rebate_level: `Nível de desconto`,
  vip_header: `Todas as Apostas Feitas em Caça-Níqueis, Jogos De Tabuleiro E Cartas, Pescaria, Vídeos, ESportes E Loterias no AHMAD77 serão acumuladas de forma permanente. Apostas válidas = Renda Permanente E Fortuna Em Dinheiro!`,
  vip_example: `Exemplo 1: Membro que atinge 1 Milhão em depósitos acumuladas, atinge Nível VIP 2, recebe bônus de R$ 210, e Desconto de 3,50%, Ao atingir 6 Milhões em depósito acumuladas, atinge nível VIP 3 e recebe bônus de R$ 800 , com desconto de 4%`,
  activity_rules: `Regras de atividade:`,
  activity_rules_1: `1. É necessário que todos os bônus tenham x3 Winover para solicitar o saque`,
  activity_rules_2: `2. Solicite o Bônus De Desconto dentro do prazo, caso se atrase será considerado abandono;`,
  activity_rules_3: `3. A participação dos membros nas atividades é contabilizada automaticamente pelo sistema, caso haja alguma objeção, prevalecerá o resultado da investigação da AHMAD77;`,
  activity_rules_4: `4. Se você esquecer sua Conta/Senha De Membro, entre em contato com o "Atendimento ao Cliente On-line 24 horas por dia" para ajudá-lo a recuperar as informações da sua Conta;`,
  activity_rules_5: `5. AHMAD77 reserva-se o direito de alterar, interromper ou cancelar esta promoção a qualquer momento.`,
  activity_rules_6: `6. Não salve jogos grátis, compre jogos grátis`,
  activity_rules_7: `7. Não é possível combinar com nenhum bônus`,
  activity_rules_8: `8. O saque Máximo por Depósito é de R$ 100.000`,
  event_instructions: 'Instruções do evento',
  agent_p1: 'Compartilhe seu link exclusivo em aplicativos e mídias sociais',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube, entre outros, apresse-se e convide a todos, os membros que se cadastrarem através do seu link exclusivo se tornarão seus agentes diretos. mas preste atenção porque os novos membros que você convida devem ser genuínos e válidos.',
  agent_step1:
    'Receba convite amigo se o primeiro depósito for realizado com sucesso, você pode ganhar um bônus de R$5. por exemplo, se você convidar 10 pessoas para o primeiro depósito, poderá ganhar R$ 50. Quanto mais pessoas você convidar, mais bônus receberá. Você pode ver seu histórico e se cadastrar na página da promoção em bônus e altas comissões.',
  agent_step2:
    'Se seus convidados apostarem R$ 1 ou mais, você já pode aproveitar uma alta comissão! Você pode ganhar até 5% de comissão vitalícia e um convite vitalício! O sistema liquidará a comissão do dia anterior às 7h do dia seguinte.',
  agent_step2_1: 'Como conseguir: Login - Promoção de convite - Receber comissão',
  agent_table1: 'As taxas de comissão de apostas para caça-níqueis, pesca e blockchain são as seguintes:',
  agent_table1_1: 'A recarga do cliente 20 aposta 200',
  person: 'pessoa',
  agent_table2_title1: 'Apostas para membros',
  agent_table2_title2: 'Apenas jogo de caça-níqueis',
  agent_table2_example:
    'Exemplo: Você tem 100 membros e o valor real total das apostas é estimado em 1 milhão de reais por dia. A comissão que você recebe naquele dia é: 1.000.000 x 1.20% = 12.000 reais. Uma renda mensal de 100.000 é super fácil de conseguir.',
  reminder: 'Lembrete',
  agent_reminder:
    'Uma conta de membro com o mesmo IP, mesmo dispositivo, nome, cartão bancário e outras informações só poderá participar uma vez, e quem violar as regras não se beneficiará deste bônus. ou o grupo será confiscado e as contas serão congeladas permanentemente',
  agent_step3_1:
    'A participação dos associados nas atividades é contabilizada automaticamente pelo sistema, caso haja alguma objeção, prevalecerá o resultado da análise realizada pelo AHMAD77',
  agent_step3_2:
    'Se você esquecer sua conta/senha de membro, entre em contato com nossa equipe de atendimento ao cliente 24 horas para ajudá-lo a recuperar as informações de sua conta.',
  agent_step3_3: 'AHMAD77 reserva-se o direito de alterar, interromper ou cancelar a promoção a qualquer momento',
  agent_step3_4: 'O agente que pode receber o bônus de comissão é em dinheiro ou créditos na carteira',
  agent_step3_5: 'Não salve jogos grátis, compre jogos grátis',
  agent_step3_6: 'Não pode ser combinado com nenhum bônus e depósito',
  agent_step3_7: 'Disponível apenas para jogos de caça-níqueis',
  promo_concluded: 'A promoção foi concluída! Verifique novamente amanhã!',
  can_only_claim: 'Só pode reivindicar bônus entre {{x}} e {{y}}, horário do Brasil',
  please_login: 'Faça login primeiro para reivindicar o bônus!',
  back_to_home: 'Back to Home',
  bonus_claimed: 'Bônus já reivindicado',
  // demo: {
  //   title: `English`,
  //   introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  // },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  // app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  product: `product`,
  invoice: `invoice`,
  // details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default pt;
