import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  Snackbar,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
// import {
//   loadCaptchaEnginge,
//   //   LoadCanvasTemplate,
//   LoadCanvasTemplateNoReload,
//   validateCaptcha,
// } from 'react-simple-captcha';
import { LoadingButton, MobileDatePicker } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import PhonenumberTextField from '../../../../components/PhonenumberTextField';
import useLocales from '../../../../hooks/useLocales';
import useAuth from '../../../../hooks/useAuth';

import { useDispatch } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

import palette from '../../../../theme/palette';
import { InputContainer, StyledInput, ThemeButton } from '../../../GlobalStyles';
import PhonenumberTextField2 from '../../../../components/PhonenumberTextField2';
import NewButton from '../../../../components/NewButton';

const StyledIconify = styled(Iconify)(({ theme }) => ({
  height: 24,
  width: 24,
  color: palette.visitor.icon,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '.75rem',
  color: palette.visitor.errorText,
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'sans-serif',
  fontWeight: 500,
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: palette.visitor.dialog.title,
  fontWeight: 600,
}));

const BirthdayText = styled(Typography)(({ theme }) => ({
  // fontSize: '.25rem',
  color: palette.visitor.dialog.otherText,
  fontWeight: 900,
}));

const RegisterButton = styled(LoadingButton)(({ theme }) => ({
  width: '100%',
  marginTop: 8,
  marginBottom: 8,
  background: palette.visitor.register.buttonBg,
  border: `2px solid ${palette.visitor.register.border}`,
  color: `${palette.visitor.register.color} !important`,
}));

const LoginDirect = styled(Typography)(({ theme }) => ({
  '&:after, &:before': {
      content: '"\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0"',
      textDecoration: 'line-through'
  }  
}));

const LoginImages = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const LoginOptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '42px',
}));

const LoginOptionItem = styled(Box)(({ theme }) => ({
  padding: '10px',
  textAlign: 'center',
  backgroundColor: palette.visitor.input.bg,
  color: '#FFF',
  width: '100%',
  height: '100%',
  fontWeight: 700,
  fontSize: '14px',
  borderRadius: '5px'
}));

const LoginDirectContainer = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  borderBottom: '1px solid #fff',
  lineHeight: '0.1em',
  margin: '10px 0 20px', 
  marginTop: '20px'
}));

const LoginDirectText = styled('span')(({ theme }) => ({
   background: '#252631',
   padding: '0 10px',
   fontWeight: 700,
   fontSize: '14px'
}));

const VerifyLink = styled(Box)(({ theme }) => ({
  height: '50%',
  textAlign: 'center',
  backgroundColor: palette.visitor.input.bg,
  color: '#FFD205',
  width: '30%',
  fontWeight: 700,
  fontSize: '14px',
  borderLeft: '1px solid #fff'
}));

const CustomHr = ({ style }) => (
  <hr style={{ borderTop: `1.5px solid ${palette.visitor.dialog.hr}`, borderBottom: 'none', height: 1, ...style }} />
);

const _registerForm = {
  userUserName: '',
  userPassword: '',
  userPhoneNumber: '',
  userFullName: '',
  userEmail: '',
  userBirthday: null,
  userReferralCode: '',
  captcha: '',
};

const Register = ({ setActiveTab, authData }) => {
  const dispatch = useDispatch();

  const { register } = useAuth();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [registerForm, setRegisterForm] = useState(_registerForm);

  const [formError, setFormError] = useState({});

  const [tncConfirmed, setTncConfirmed] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
  });

  const [validationErrors, setValidationErrors] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  
  const [loginOption, setLoginOption] = useState('email');

  const platformText = useMemo(() => {
    return authData?.platform.charAt(0).toUpperCase() + authData?.platform.slice(1);
  }, [authData]);

  const isNotValidSubmit = useMemo(() => {
    if (authData) {
      return (
        !tncConfirmed ||
        !registerForm?.userUserName ||
        // !registerForm?.captcha ||
        !registerForm?.userEmail ||
        !registerForm.userFullName ||
        !registerForm?.userPhoneNumber
      );
    }

    return (
      !tncConfirmed ||
      !registerForm?.userUserName ||
      // !registerForm?.captcha ||
      !registerForm?.userEmail ||
      !registerForm.userFullName ||
      !registerForm?.userPassword ||
      !registerForm?.userPhoneNumber
    );
  }, [registerForm, tncConfirmed, authData]);

  useEffect(() => {
    const _referralCode = localStorage.getItem('referralCode');

    if (_referralCode) {
      setRegisterForm((prevState) => ({ ...prevState, userReferralCode: _referralCode }));
    }

    // setTimeout(() => {
    //   loadCaptchaEnginge(4);
    // }, 1000);
  }, []);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setRegisterForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async () => {
    if (!isValid()) return;

    setIsLoading(true);

    const requestData = {
      'user-username': registerForm?.userUserName.trim(),
      'user-password': registerForm?.userPassword.trim(),
      'user_profile-no_hp': registerForm?.userPhoneNumber,
      'user-full_name': registerForm?.userFullName,
      'user-email': registerForm?.userEmail,
      // 'user_profile-birthday': moment(registerForm?.userBirthday).format('YYYY-MM-DD'),
      'user-referral_code': registerForm?.userReferralCode,
    };

    if (authData) {
      const _authData = JSON.parse(JSON.stringify(authData));
      delete _authData?.platform;
      requestData['user_third_party_login-platform'] = authData?.platform;

      requestData['user_third_party_login-data'] = JSON.stringify(_authData);
    }

    try {
      await register(requestData);
      const _referralCode = localStorage.getItem('referralCode');
      if (_referralCode) localStorage.removeItem('referralCode');
      dispatch(openLoginSignup({ open: false, isLogin: true }));
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Enter key handler');
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [registerForm]);

  useEffect(() => {
    if (authData) {
      setRegisterForm((prev) => ({
        ...prev,
        userFullName: `${authData?.first_name || ''} ${authData?.last_name || ''}`,
        userEmail: `${authData?.email || ''}`,
      }));
    }
  }, [authData]);

  const isValid = () => {
    let valid = true;
    const _error = {};

    // username
    if (registerForm?.userUserName?.trim()?.length < 3) {
      valid = false;
      _error.userUserName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('username'), y: '3' });
    } else {
      _error.userUserName = '';
    }

    // password
    if (registerForm?.userPassword?.trim()?.length < 5 && !authData) {
      valid = false;
      _error.userPassword = translate('invalid_x_must_be_atleast_y_characters', { x: translate('password'), y: '5' });
    } else {
      _error.userPassword = '';
    }

    // fullname
    if (!registerForm?.userFullName.trim()) {
      valid = false;
      _error.userFullName = translate('invalid_x_must_be_atleast_y_characters', { x: translate('full_name'), y: '1' });
    } else {
      _error.userFullName = '';
    }

    // email
    if (!registerForm?.userEmail.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      valid = false;
      _error.userEmail = translate('invalid_x', { x: translate('email') });
    } else {
      _error.userEmail = '';
    }

    // phone number
    if (!registerForm?.userPhoneNumber.trim()) {
      valid = false;
      _error.userPhoneNumber = translate('x_is_required', { x: translate('phone_number') });
    } else if (registerForm?.userPhoneNumber.trim().length < process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH) {
      valid = false;
      _error.userPhoneNumber = translate(`invalid_x_must_be_atleast_y_characters`, {
        x: translate('phone_number'),
        y: process.env.REACT_APP_MOBILENUMBER_MIN_LENGTH,
      });
    } else if (registerForm?.userPhoneNumber.trim().length > process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH) {
      valid = false;
      _error.userPhoneNumber = translate('phone_num_x_max', { x: process.env.REACT_APP_MOBILENUMBER_MAX_LENGTH });
    } else {
      _error.userPhoneNumber = '';
    }

    // birthday
    // if (!registerForm?.userBirthday) {
    //   valid = false;
    //   _error.userBirthday = translate('x_is_required', { x: translate('birthday') });
    // } else {
    //   _error.userBirthday = '';
    // }

    // if (validateCaptcha(registerForm?.captcha?.trim(), false) === true) {
    //   _error.userCaptcha = '';
    // } else {
    //   valid = false;
    //   _error.userCaptcha = translate('captcha_not_match');
    // }

    setFormError((prevState) => _error);

    return valid;
  };

  const LinkText = ({ children }) => <span role="presentation" onMouseDown={() => dispatch(openLoginSignup({ open: true, isLogin: true }))} style={{ cursor: 'pointer', fontWeight: 900, color: palette.visitor.link }}>{children}</span>;

  const TncText = ({ children }) => <span role="presentation" onMouseDown={() => console.log('click terms')} style={{ cursor: 'pointer', fontWeight: 900, color: palette.visitor.link }}>{children}</span>;

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '0.6rem' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}
      {authData ? (
        <Alert sx={{ width: '100%', my: '0.6rem' }}>
          {translate('linked_successfully_x', {
            x: platformText,
          })}
        </Alert>
      ) : (
        ''
      )}

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '0.6rem' }}>
        <LabelText>{translate('register')}</LabelText>
        <NewButton
          variant="contained"
          onClick={() => dispatch(openLoginSignup({ isLogin: true, open: false }))}
          sx={{ borderRadius: '0.6rem' }}
          label={translate('Back')}
        />
      </Box>
      
      {/* <LoginOptionContainer sx={{ mb: '0.6rem' }}>
          <LoginOptionItem onClick={() => setLoginOption('email')} sx={{ width: '110%', backgroundColor: loginOption === 'email' ? '#49485A' : '#31303D'}}>
            Email
          </LoginOptionItem>
          <LoginOptionItem onClick={() => setLoginOption('mobile')}  sx={{width: '110%', backgroundColor: loginOption === 'mobile' ? '#49485A' : '#31303D'}}>
            Mobile Number
          </LoginOptionItem>
        </LoginOptionContainer>
      */}
      <Box sx={{ mb: '0.6rem' }}>
          <InputContainer direction="row">
            <StyledInput
              name="userUserName"
              value={registerForm?.userUserName}
              onChange={(e) => handleFormChange(e)}
              placeholder={translate('username')}
            />
          </InputContainer>
          {formError?.userUserName && <ErrorText>{formError?.userUserName}</ErrorText>}
        </Box>
      {/*
      {loginOption === 'email' ? (
        <Box sx={{ mb: '0.6rem' }}>
        <InputContainer direction="row">
          <StyledInput
            name="userEmail"
            value={registerForm?.userEmail}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('email')}
          />
        </InputContainer>
        {formError?.userEmail && <ErrorText>{formError?.userEmail}</ErrorText>}
      </Box>
      ) : (
        <Box sx={{ mb: '0.6rem' }}>
          <InputContainer direction="row">
            <PhonenumberTextField2
              name="userPhoneNumber"
              value={registerForm?.userPhoneNumber}
              placeholder={translate('phone_number')}
              onChange={(e) => setRegisterForm((prevState) => ({ ...prevState, userPhoneNumber: e }))}
              fullWidth
            />
          </InputContainer>
          {formError?.userPhoneNumber && (
            <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userPhoneNumber}</span>
          )}
        </Box>
      )}
      */}
      {!authData ? (
        <Box sx={{ mb: '0.6rem' }}>
          <InputContainer direction="row">
            <StyledInput
              type={showPassword ? 'text' : 'password'}
              name="userPassword"
              value={registerForm?.userPassword}
              onChange={(e) => handleFormChange(e)}
              placeholder={translate('Password')}
            />
            <StyledIconify
              icon={showPassword ? 'ph:eye-light' : 'ph:eye-closed-light'}
              sx={{ cursor: 'pointer' }}
              onClick={() => setShowPassword((prevState) => !prevState)}
            />
          </InputContainer>
          {formError?.userPassword && <ErrorText>{formError?.userPassword}</ErrorText>}
        </Box>
      ) : (
        ''
      )}

      <Box sx={{ mb: '0.6rem' }}>
        <InputContainer direction="row">
          <PhonenumberTextField2
            name="userPhoneNumber"
            value={registerForm?.userPhoneNumber}
            placeholder={translate('phone_number')}
            onChange={(e) => setRegisterForm((prevState) => ({ ...prevState, userPhoneNumber: e }))}
            fullWidth
          />
        </InputContainer>
        {formError?.userPhoneNumber && (
          <span style={{ fontSize: '.75rem', color: 'red' }}>{formError?.userPhoneNumber}</span>
        )}
      </Box>

      <Box sx={{ mb: '0.6rem' }}>
        <InputContainer direction="row">
          <StyledInput
            name="userEmail"
            value={registerForm?.userEmail}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('email')}
          />
        </InputContainer>
        {formError?.userEmail && <ErrorText>{formError?.userEmail}</ErrorText>}
      </Box>
      <Box sx={{ mb: '0.6rem' }}>
        <InputContainer direction="row">
          <StyledInput
            name="userFullName"
            value={registerForm?.userFullName}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('full_name')}
          />
        </InputContainer>
        {formError?.userFullName && <ErrorText>{formError?.userFullName}</ErrorText>}
      </Box>


      {/* <Box sx={{ mb: '0.6rem' }}>
        <InputContainer direction="row">
          <StyledInput
            name="verificationCode"
            value={registerForm?.verificationCode}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('verification_code')}
          />
          <VerifyLink>
            Verify
          </VerifyLink>
        </InputContainer>
        {formError?.verificationCode && <ErrorText>{formError?.verificationCode}</ErrorText>}
      </Box>
    */}
      {/* <Box sx={{ mb: '0.6rem' }}>
        <InputContainer direction="row">
          <MobileDatePicker
            label={translate('birthday')}
            value={registerForm.userBirthday}
            inputFormat="dd/MM/yyyy"
            disableCloseOnSelect={false}
            // DialogProps={{className: 'standard-FS'}}
            onChange={(v) => setRegisterForm((prevState) => ({ ...prevState, userBirthday: v }))}
            renderInput={(params) => <StyledInput {...params} placeholder={translate('birthday')} />}
            maxDate={new Date()}
          />
        </InputContainer>
        <BirthdayText>{translate('birthday_bonus')}</BirthdayText>
        {formError?.userBirthday && <ErrorText>{formError?.userBirthday}</ErrorText>}
      </Box> */}

      <Box sx={{ '& .MuiPaper-root': { boxShadow: 'none !important' } }}>
        <Accordion sx={{
          backgroundColor: '#520000'
        }}>
          <AccordionSummary
            expandIcon={<Iconify icon="ooui:expand" />}
            sx={{
              p: 0,
              minHeight: 'unset !important',
              '& .MuiAccordionSummary-content': { my: '0 !important' },
              mb: 1
            }}
          >
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 900 }}>
              {translate('referral_code')} ({translate('optional')})
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <InputContainer direction="row">
              <StyledInput
                name="userReferralCode"
                value={registerForm?.userReferralCode}
                onChange={(e) => handleFormChange(e)}
                placeholder={translate('please_input_your_x', { x: translate('referral_code') })}
              />
            </InputContainer>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* <Box sx={{ mb: '0.6rem' }}>
        <InputContainer direction="row">
          <StyledInput
            name="userReferralCode"
            value={registerForm?.userReferralCode}
            onChange={(e) => handleFormChange(e)}
            placeholder={translate('please_input_your_x', { x: translate('referral_code') })}
          />
        </InputContainer>
      </Box> */}

      {/* <Box sx={{ mb: '0.6rem' }}>
        <InputContainer direction="row">
          <StyledInput
            name="captcha"
            placeholder={translate('captcha')}
            value={registerForm?.captcha}
            onChange={(e) => handleFormChange(e)}
          />
          <Box component={'span'} sx={{ height: '30px', display: 'flex', alignItems: 'center' }}>
            <LoadCanvasTemplateNoReload />
          </Box>
        </InputContainer>
        {formError?.userCaptcha && <ErrorText>{formError?.userCaptcha}</ErrorText>}
      </Box> */}

      <Stack direction={'row'} alignItems={'center'} sx={{ mt: 2, mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{ width: 'fit-content', color: palette.visitor.dialog.otherText }}
              value={tncConfirmed}
              checked={tncConfirmed}
              onChange={(e) => setTncConfirmed(e.target.checked)}
            />
          }
          label={
            <FooterText>
              {translate('confirm_18')}{' '}
              <TncText>{translate('terms_of_service')}</TncText>
            </FooterText>
          }
        />
      </Stack>

      <NewButton
        fullWidth
        onClick={() => onSubmit()}
        disabled={isNotValidSubmit}
        sx={isNotValidSubmit ? { filter: 'grayscale(1)', color: '#dfd6d6!important' } : {}}
        label={translate('sign_up')}
      />

      <Box
      sx={{
        mt: 3,
        fontSize: '14px',
        fontWeight: 700,
        textAlign: 'center'
      }}
    >
      {translate('already_have_account')} <LinkText>{translate('login')}</LinkText>
      </Box>
      
      {/* <LoginDirectContainer>
        <LoginDirectText>
            Or log in directly with
        </LoginDirectText>
      </LoginDirectContainer>
      <LoginImages>
        <img src={require('../../../../assets/google-icon.png')} alt="Loading" style={{ width: '21px', flexBasis: '15%', padding: '18px 18px' }} />
        <img src={require('../../../../assets/telegram-icon.png')} alt="Loading" style={{ width: '21px', flexBasis: '15%', padding: '18px 18px' }} />
      </LoginImages>
    */}
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Register.propTypes = {
  setActiveTab: PropTypes.func,
};

export default Register;
