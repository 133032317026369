// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const zhHant = {
  game: `遊戲`,
  games: `遊戲`,
  sport: `運動`,
  center: `中心`,
  mines: `礦場`,
  crash: `碰撞`,
  lucky_colour: `幸運色`,
  dice: `骰子`,
  limbo: `地獄邊境`,
  lucky_gift: `幸運禮物`,
  all_games: `所有遊戲`,
  game_history: `遊戲歷史`,
  coupon_code: `優惠券代碼`,
  telegram: `電報`,
  customer_service: `客戶服務`,
  player: `玩家`,
  winner: `優勝者`,
  all_bets: `所有投注`,
  my_bets: `我的賭注`,
  name: `姓名`,
  time: `時間`,
  bet_amount: `投注金額`,
  prediction: `預言`,
  payout: `支出`,
  login: `登入`,
  register: `登記`,
  username_placeholder: `使用者名稱`,
  please_input_your_x: `請輸入您的 {{x}}`,
  captcha: `驗證碼`,
  forgot_password: `忘記密碼`,
  minor_note: `要造訪此網站, 請確保您已年滿 18 歲並同意`,
  terms_of_service: `服務條款`,
  by_signing_in: '登入或註冊即表示您同意我們的',
  dont_have_account: `沒有帳戶?`,
  sign_up: `報名`,
  username: `使用者名稱`,
  email: `電子郵件`,
  full_name: `全名`,
  confirm_18: `我確認我已年滿 18 歲並且我已閱讀`,
  already_have_account: `已經有帳戶?`,
  share: `分享`,
  deposit: `訂金`,
  bonus: `獎金`,
  app: `應用程式`,
  load_more: `裝載更多`,
  more: `更多`,
  details: `細節`,
  helps: `幫助`,
  kyc_policy: `KYC 政策`,
  privacy_policy: `Privacy Policy`,
  responsible_gaming: `隱私權政策`,
  terms_and_condition: `附帶條約`,
  faq: `常問問題`,
  about_us: `關於我們`,
  amount: `數量`,
  extra: `額外的`,
  deposit_bonus: `存款獎金`,
  do_not_participate: `不參加促銷活動`,
  payment_problem: `付款問題`,
  click_for_help: `點擊尋求協助`,
  deposit_notes: `存款票據`,
  available_balance: `可用餘額`,
  withdraw: `提取`,
  withdrawal_notes: `提款須知`,
  withdrawal_note1: `目前提款次數`,
  withdrawal_note1_1: `貴賓`,
  withdrawal_note2: `提款費用:`,
  min: `最小:`,
  max: `最大限度:`,
  opening_times: `開放時間:`,
  withdrawal_note3: `1.最快10分鐘內提現`,
  withdrawal_note4: `2.確保您的提款資訊準確, 如果提款資訊錯誤, 可能會導致您的資金損失。`,
  withdrawal_note5: `3.如有任何疑問, 請隨時與我們聯繫。`,
  notifications: `通知`,
  invalid_x: `無效的 {{x}}`,
  captcha_not_match: `驗證碼不匹配`,
  invalid_x_must_be_atleast_y_characters: `無效的 {{x}}, 必須至少 {{y}} 人物`,
  x_is_required: `{{x}} 是必須的`,
  phone_number: '電話號碼',
  phone_num_x_max: `電話號碼上限 {{x}} 數位`,
  email_sent: `郵件已發送, 請查收郵件。`,
  enter_valid_email: `請輸入有效電子郵件。`,
  confirm: `確認`,
  password_atleast_6_characters: `密碼必須至少6個字元。`,
  password_reset_success: `密碼重置成功! `,
  new_password: `新密碼`,
  bank: '銀行',
  bank_transfer: `銀行轉帳`,
  bank_name: `銀行名`,
  bank_branch: `銀行BSB`,
  pay_id: `支付ID`,
  account_name: `帳戶名稱`,
  account_number: `帳號`,
  drop_or_select: `刪除或選擇文件`,
  drop_files_here: `將文件拖放到此處`,
  transfer_to: `傳送到`,
  upload_receipt: `上傳收據`,
  select_bonus: `選擇獎金`,
  no_promotion: `無促銷`,
  please_upload_receipt: `請上傳收據`,
  bank_account: `銀行帳戶`,
  add_account: `新增帳戶`,
  account_holder_name: `帳戶持有人姓名`,
  name_of_the_account_holder: `帳戶持有人姓名`,
  fill_in_bank_account: `填寫銀行帳戶`,
  withdraw_information: `提現訊息`,
  note: `筆記`,
  amount_withdrawn: `提取金額`,
  amount_no_exceed_balance: `提款金額不得超過可用餘額`,
  minimum_withdraw_amount_is_x: `最低提款金額為 {{x}}`,
  transaction_history: `交易紀錄`,
  date_from: `日期從`,
  date_to: `日期至`,
  account_name_must_be_4: `帳戶名稱長度必須大於 4`,
  account_number_must_be_x: `帳號長度必須為 10-14`,
  minimum_length_is_x: `最小長度為 {{x}}`,
  maximum_length_is_x: `最大長度為 {{x}}`,
  payment_method: `付款方式`,
  mode: `模式`,
  no_bank_account: `您沒有任何可提款的帳戶, 立即添加`,
  please_select_bank: `請選擇銀行帳戶`,

  bet_history: `投注歷史`,
  game_provider: `遊戲提供者`,
  bet_count: `投注次數`,
  win_loss: `贏/輸`,
  rebate: `回扣`,
  turnover: `週轉`,
  game_category: `遊戲類別`,

  you_have_x_unread_messages: `你有 {{x}} 未讀訊息`,
  deposit_amount_between_x_and_y: `存款金額必須介於 {{x}} 和 {{y}}`,
  minimum_amount_is_x_y: `最低金額為 {{x}}{{y}}`,

  // all
  all: `全部`,
  title: `標題`,
  date: `日期`,
  type: `類型`,
  status: `地位`,
  remark: `評論`,
  category: `類別`,
  please_select_x: `請選擇 {{x}}`,
  save: `節省`,

  change_password: `更改密碼`,
  current_password: `目前密碼`,
  confirm_password: `確認新密碼`,
  submit: `提交`,
  password_changed: `密碼已更改`,
  x_must_be_y_characters: `{{x}} 必須至少 {{y}} 人物`,
  reset_password: `重新設密碼`,
  casino: `賭場`,
  slots: `老虎機`,
  live_casino: `真人娛樂場`,
  fishing: `釣魚`,
  sports: `運動的`,
  cards: `牌`,
  clear: `清除`,
  search: `搜尋`,
  new: `新的`,
  logout: `登出`,
  choose_date: `選擇日期`,
  select_both_date: `選擇日期從和到`,
  from: `從`,
  to: `到`,
  apply: `申請`,
  promotions: `促銷活動`,
  learn_more: `了解更多`,
  password_doesnt_match: `密碼不匹配`,
  cancel: `取消`,
  confirm_to_pay: `確認付款`,
  deposit_amount: `存款金額`,
  loading_please_wait: `加載請稍候`,
  account: `帳戶`,
  profile: `輪廓`,
  bank_list: `銀行名單`,
  in_maintenance: `維護中`,
  birthday: `生日`,
  birthday_bonus: `輸入您的生日即可獲得獎金!  🎉🎁`,
  referral_code: `推薦碼`,
  referral: `推薦`,
  my_referral: `我的推薦`,
  my_claim: `我的索賠`,
  claim_profit: `索取利潤`,
  available_profit: `可用利潤`,
  claimed_profit: `聲稱利潤`,
  all_time_profit: `所有時間利潤`,
  referral_note_on_x: `每次您的朋友玩遊戲時都可以賺取利潤 {{x}}`,
  tier: `等級`,
  commission: `委員會`,
  referred_member: `推薦會員`,
  profit_earned: `賺取利潤`,
  account_information: `帳戶資訊`,
  invite_friends: `邀請朋友`,
  how_it_work: `它是如何工作的?`,
  invite_note: `當您的朋友使用您的推薦代碼註冊時, 即可獲得獨家推薦獎金!`,
  share_via: `分享透過`,
  referral_link: `推薦連結`,
  total_profit: `利潤總額`,
  copied: `已複製`,
  nothing_to_claim: `沒有什麼可索賠的`,
  claim_history: `索賠歷史`,
  downline: `我的下線`,
  last_x_digit_bank_ref_number: `最後的 {{x}} 銀行參考號碼的數字`,
  bank_ref_number_validation_x: `銀行參考號碼為必填項且必須是 {{x}} 字元長度`,
  sort: '種類',
  login_directly: `直接登入`,
  our_game_provides: `我們的遊戲提供者`,
  refer_and_reward: `推薦與獎勵`,
  linked_successfully_x: `已成功連結至 {{x}}! 請首次註冊您的帳號`,
  more_x_of_y: `更多的 {{x}}/{{y}}`,
  default: `預設`,
  promotion: `晉升`,
  daily_mission: '每日任務',
  agent: '代理人',
  contact_us: '聯絡我們',
  download: '下載',
  cash_claim: '現金索賠',
  demo: '示範',
  favorites: '收藏夾',
  popular: '受歡迎的',
  claim_bonus: `領取獎金`,
  insufficient_balance: `餘額不足`,
  currently_displaying: '目前顯示 {{x}} {{y}} 遊戲出 {{z}}',
  all_x: '全部 {{x}}',
  no_results: '未找到結果',
  search_games: '搜尋遊戲',
  please_scan_using_your: `請使用您的掃描`,
  vip_level: `貴賓級別`,
  bonus_direct: `直接獎金`,
  rebate_level: `回扣水平`,
  vip_header: `AHMAD77 在老虎機、棋盤和紙牌遊戲、釣魚、視頻、體育和彩票遊戲上的所有投注都將永久累積。有效投注=永久收入和金錢財富! `,
  vip_example: `
  例1: 會員累計存款達100萬, 達到VIP等級2, 獲得獎金R$210, 回饋3.50%, 當會員累計存款達600萬, 達到VIP等級3, 獲得獎金R$800 , 回饋 4%`,
  activity_rules: `活動規則: `,
  activity_rules_1: `1.所有獎金必須有x3 Winover才能請求提款`,
  activity_rules_2: `2. 請於期限內領取返利, 逾期則視為放棄; `,
  activity_rules_3: `3.會員參與活動由系統自動統計, 如有異議, 以AHMAD77調查結果為準; `,
  activity_rules_4: `4.如果您忘記會員帳號/密碼, 請聯絡「24小時線上客服」幫您找回帳號資訊; `,
  activity_rules_5: `5. AHMAD77保留隨時更改、停止或取消此促銷活動的權利。`,
  activity_rules_6: `6.不要儲存免費遊戲, 購買免費遊戲`,
  activity_rules_7: `7. 不能與任何獎金合併`,
  activity_rules_8: `8. 每次存款最高提款額為 R$100,000`,
  event_instructions: '活動說明',
  agent_p1: '跨應用程式和社交媒體分享您的獨特鏈接',
  agent_p2:
    'Facebook、Instagram、Tiktok、Telegram、WhatsApp、YouTube等, 趕緊邀請大家, 透過您的專屬連結註冊的會員將成為您的直接代理商。但請注意, 您邀請的新會員必須是真實有效的。',
  agent_step1:
    '獲得邀請的朋友首次存款成功, 即可贏得R$5獎金。例如, 如果您邀請 10 人進行首次存款, 您可以賺取 R$50。您邀請的人越多, 您收到的獎金就越多。您可以在使用和高額佣金的促銷頁面上查看您的歷史記錄並註冊。',
  agent_step2:
    '如果您的客人下注R$1或以上, 您就已經可以享受高額佣金了! 您可以獲得高達 5% 的終身佣金和終身邀請! 系統將在隔天早上7點結算前一天的佣金。',
  agent_step2_1: '取得方式: 登入-邀請促銷-收取佣金',
  agent_table1: '老虎機、釣魚和區塊鏈的投注佣金率如下: ',
  agent_table1_1: '客戶儲值20投注200',
  person: '人',
  agent_table2_title1: '會員投注',
  agent_table2_title2: '僅限老虎機遊戲',
  agent_table2_example:
    '範例: 您有 100 名會員, 投注的實際總價值估計為每天 100 萬雷亞爾。您當天收到的佣金為: 1,000,000 x 1.20% 12,000 雷亞爾。月入10萬是超級容易實現的。',
  reminder: '提醒',
  agent_reminder:
    '同一IP、同一設備、姓名、銀行卡等資訊的會員帳戶只能參與一次, 違規者將無法享有此獎金。否則該群組將被沒收, 帳戶將被永久凍結',
  agent_step3_1: '會員參與活動由系統自動統計, 如有異議, 以AHMAD77分析結果為準',
  agent_step3_2: '如果您忘記會員帳號/密碼, 請聯絡我們的24小時客服團隊幫您找回帳號訊息',
  agent_step3_3: 'AHMAD77 保留隨時更改、中斷或取消促銷活動的權利',
  agent_step3_4: '代理能夠收到佣金獎金的條件是現金或錢包裡的積分',
  agent_step3_5: '不要保存免費遊戲, 購買免費遊戲',
  agent_step3_6: '不能與任何獎金和存款合併',
  agent_step3_7: '僅適用於老虎機遊戲',
  promo_concluded: '促銷已結束! 明天再回來看看! ',
  can_only_claim: '只能在巴西時間 {{x}} 到 {{y}} 之間領取獎金',
  please_login: '請先登入才能領取獎金! ',
  back_to_home: '回首頁',
  bonus_claimed: '獎金已領取! ',
  terms_n_conditions: `附帶條約`,
  the_activity_x_in: `活動 {{x}} 在`,
  starts: `開始`,
  ends: `結束`,
  list_of_winners: `得獎名單`,
  days: `天`,
  hours: `小時`,
  minutes: `分分鐘`,
  seconds: `秒數`,
  total_amount_received: `收到的總金額`,
  claim: `宣稱`,
  security: `安全`,
  certification: `認證`,
  menu: `選單`,
  win: '贏',
  contact_us_text: `聯絡我們`,
  live_chat: `線上聊天`,
  chat_now: `現在聊天`,
  gaming_license: `博彩牌照`,
  license_text_x: `{{x}} 是庫拉索島政府許可和監管的實體, 並根據博彩服務提供商主許可證 (N.V #365/JAZ) 合法運營`,
  game_name: `遊戲名稱`,
  home: `家`,
  card_game: `紙牌遊戲`,
  vip_club: `貴賓俱樂部`,
  more_info: `更多資訊`,
  apply_now: `現在申請`,
  instant: `立即的`,
  instant_game: `即時遊戲`,
  card: `卡片`,
  'e-sports': `電競`,
  new_to_x: `12 AHMAD77 新手`,
  create_an_account: `建立一個帳戶`,
  back: `後退`,
  password: `密碼`,
  optional: `選修的`,
  balance: `平衡`,
  withdraw_amount: `提款數量`,
  referral1_title_x: `每次推薦可獲得 1500 美元獎金和高達 30% 的佣金`,
  referral1_desc: `與朋友分享高達 50% 的佣金, 激勵他們玩更多遊戲! `,

  referral2_title_x: `每次推薦均可享 {{x}}1,500 獎金`,
  referral2_desc: `每位受邀用戶都會為您帶來%1500 獎金! 您的朋友每人還將獲得 200 美元的獎金。`,

  referral3_title: `賺取高達 30% 的佣金`,
  referral3_desc: `邀請您的朋友並在他們玩得開心的同時賺錢! 從他們的投注中獲得高達30%的平台利潤! `,

  referral4_title: `獨家聯盟計劃`,
  referral4_desc: `你有大量的觀眾和追隨者嗎？成為我們的合作夥伴, RevShare 利潤高達 60%。從推薦的玩家身上賺取一定比例的淨收入。加入進來, 讓我們一起享受成功! `,
  become_a_partner: `成為一個合夥人（參與者`,
  total_referrals: `總推薦數`,
  total_paid: `總支付`,
  refer_now: `現在參考`,
  live_rewards: `現場獎勵`,
  lobby: `大廳`,
  level: `等級`,
};

export default zhHant;
