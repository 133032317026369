import { Box, Button, Typography, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from '../../redux/store';
import { setSiteSettings } from '../../redux/slices/lookup';
import CustomModal from '../../components/CustomModal';
import Image from '../../components/Image';
import useLocales from '../../hooks/useLocales';
import conObj from '../../utils/connection-assistant';

const ModalBox = styled(Box)(({ theme }) => ({
  padding: '1rem 2rem',
  [theme.breakpoints.only('xs')]: {
    padding: '1rem 1rem',
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 600,
  color: '#dfdfdf',
  [theme.breakpoints.only('xs')]: {
    fontSize: '3.35vw',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
}));

const SubTitleText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 15,
  fontWeight: 600,
  color: '#dfdfdf',
  [theme.breakpoints.only('xs')]: {
    fontSize: '2.5vw',
  },
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 15,
  color: '#dfdfdf',
  paddingBottom: '1rem',
  [theme.breakpoints.only('xs')]: {
    fontSize: '2.5vw',
  },
}));

const ClaimButton = styled(Button)(({ theme }) => ({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '1rem',
  background: '#f2c556',
  color: '#fff',
  '&:hover': {
    background: '#f2c556',
  },
  [theme.breakpoints.only('xs')]: {
    fontSize: '2.5vw',
  },
}));

const PopupBanner = ({ popupBanner }) => {
  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const { enqueueSnackbar } = useSnackbar();

  const openPopup = () => {
    return siteSettings?.popups.find((f) => f.banner_slide_id === popupBanner?.banner_slide_id)?.showPopup;
  };

  const handleClaimBonus = async () => {
    try {
      await conObj.post('transactions/manual_claim_bonus.json', {
        'transaction-promotion_id': popupBanner?.promotion_id,
      });

      handlePopupClose();

      enqueueSnackbar(translate('bonus_claimed'));
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  /**
   * toggle banner showPopup === false to closse
   */
  const handlePopupClose = () => {
    const currentBanner = siteSettings?.popups.find((f) => f.banner_slide_id === popupBanner?.banner_slide_id);

    const listWithoutCurrent = siteSettings?.popups.filter((f) => f.banner_slide_id !== popupBanner?.banner_slide_id);

    dispatch(
      setSiteSettings({ ...siteSettings, popups: [...listWithoutCurrent, { ...currentBanner, showPopup: false }] })
    );
  };

  return (
    <CustomModal
      open={openPopup()}
      onClose={() => handlePopupClose()}
      paperPropsSx={{
        width: '515px',
        height: '427px',
        cursor: 'pointer',
      }}
    >
      <ModalBox
        onClick={() =>
          popupBanner?.url
            ? window.open(
                popupBanner?.url,
                '_blank'
                // 'noopener noreferrer'
              )
            : {}
        }
      >
        <TitleText>{popupBanner?.title}</TitleText>

        {popupBanner?.subtitle && <SubTitleText>{popupBanner?.subtitle}</SubTitleText>}

        <Box sx={{ my: 2 }}>
          {popupBanner?.banner && (
            <Image
              src={popupBanner?.banner}
              alt="desktop"
              sx={{
                display: popupBanner?.banner_mobile ? { sm: 'block', xs: 'none' } : { display: 'block' },
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            />
          )}
          {popupBanner?.banner_mobile && (
            <Image
              src={popupBanner?.banner_mobile}
              alt="mobile"
              sx={{
                display: popupBanner?.banner ? { sm: 'none', xs: 'block' } : { display: 'block' },
                borderRadius: '10px',
              }}
            />
          )}
        </Box>

        {popupBanner?.description && <DescriptionText>{popupBanner?.description}</DescriptionText>}
      </ModalBox>
      {popupBanner?.is_manual_claimable && (
        <ClaimButton onClick={() => handleClaimBonus()} disabled={!popupBanner?.is_claimable_now}>
          Claim now!
        </ClaimButton>
      )}
    </CustomModal>
  );
};

export default PopupBanner;
