// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const my = {
  game: `Game`,
  games: `Games`,
  sport: `Sport`,
  center: `Center`,
  mines: `MINES`,
  crash: `CRASH`,
  lucky_colour: `LUCKY COLOUR`,
  dice: `DICE`,
  limbo: `LIMBO`,
  lucky_gift: `LUCKY GIFT`,
  all_games: `All Games`,
  game_history: `Game History`,
  coupon_code: `Coupon Code`,
  telegram: `Telegram`,
  customer_service: `Customer Service`,
  player: `Player`,
  winner: `Winner`,
  all_bets: `All Bets`,
  my_bets: `My Bets`,
  name: `Name`,
  time: `Time`,
  bet_amount: `Bet Amount`,
  prediction: `Prediction`,
  payout: `Payout`,
  login: `Login`,
  register: `Register`,
  username_placeholder: `Username`,
  please_input_your_x: `Please Input Your {{x}}`,
  captcha: `CAPTCHA`,
  forgot_password: `Forgot Password`,
  minor_note: `To visit this site, make sure you are over 18 and agree`,
  terms_of_service: `Terms of Service`,
  dont_have_account: `Don't have an account?`,
  sign_up: `Sign Up`,
  username: `Username`,
  email: `Email`,
  full_name: `Full Name`,
  confirm_18: `I confirm that I am 18 years old and I have read the`,
  already_have_account: `Already have an account?`,
  share: `Share`,
  deposit: `Deposit`,
  bonus: `Bonus`,
  app: `App`,
  load_more: `Load More`,
  details: `Details`,
  helps: `HELPS`,
  kyc_policy: `KYC Policy`,
  privacy_policy: `Privacy Policy`,
  responsible_gaming: `Responsible gaming`,
  terms_and_condition: `Terms and Condition`,
  faq: `FAQ`,
  about_us: `ABOUT US`,
  amount: `Amount`,
  extra: `Extra`,
  deposit_bonus: `Deposit Bonus`,
  do_not_participate: `Do not participate in promotions`,
  payment_problem: `Payment Problem`,
  click_for_help: `Click for help`,
  deposit_notes: `Deposit Notes`,
  available_balance: `Available Balance`,
  withdraw: `Withdraw`,
  withdrawal_notes: `Withdrawal Notes`,
  withdrawal_note1: `Number of withdrawals in current`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Withdrawal fee:`,
  min: `Min:`,
  max: `Max:`,
  opening_times: `Opening times:`,
  withdrawal_note3: `1.Fastest withdrawal time within 10 minutes`,
  withdrawal_note4: `2.Make sure your withdrawal information is accurate, if the withdrawal information is wrong, it may cause your funds to lose.`,
  withdrawal_note5: `3.Please feel free to contact us if you have any questions.`,
  notifications: `Notifications`,
  invalid_x: `Invalid {{x}}`,
  captcha_not_match: `Captcha Does Not Match`,
  invalid_x_must_be_atleast_y_characters: `Invalid {{x}}, must be atleast {{y}} characters`,
  x_is_required: `{{x}} is required`,
  phone_number: 'Phone Number',
  phone_num_x_max: `Phone Number maximum {{x}} digits`,
  email_sent: `Email sent, please check your email.`,
  enter_valid_email: `Please enter a valid email.`,
  confirm: `Confirm`,
  password_atleast_6_characters: `Password must be atleast 6 characters.`,
  password_reset_success: `Password reset success!.`,
  new_password: `New Password`,
  bank: 'Bank',
  bank_transfer: `Bank Transfer`,
  bank_name: `Bank Name`,
  account_name: `Account Name`,
  account_number: `Account Number`,
  drop_or_select: `Drop or Select file`,
  drop_files_here: `Drop files here`,
  transfer_to: `Transfer To`,
  upload_receipt: `Upload Receipt`,
  select_bonus: `Select Bonus`,
  no_promotion: `No Promotion`,
  please_upload_receipt: `Please upload receipt`,
  bank_account: `Bank Account`,
  add_account: `Add Account`,
  account_holder_name: `Account Holder Name`,
  name_of_the_account_holder: `Name of the Account Holder`,
  fill_in_bank_account: `Fill in Bank Account`,
  withdraw_information: `Withdraw Information`,
  note: `Note`,
  amount_withdrawn: `Amount withdrawn`,
  amount_no_exceed_balance: `Withdraw Amount should not exceed the available balance`,
  minimum_withdraw_amount_is_x: `Minimum Withdraw amount is {{x}}`,
  transaction_history: `Transaction History`,
  date_from: `Date From`,
  date_to: `Date To`,
  account_name_must_be_4: `Account Name must be greater than 4 in length`,
  account_number_must_be_x: `Account number must be 10-14 in length`,
  payment_method: `Payment Method`,
  no_bank_account: `You do not have any account to withdraw to, Add now`,
  please_select_bank: `Please select a Bank Account`,

  bet_history: `Bet History`,
  game_provider: `Game Provider`,
  bet_count: `Bet Count`,
  win_loss: `Win/Loss`,
  rebate: `Rebate`,
  turnover: `Turnover`,
  game_category: `Game Category`,

  you_have_x_unread_messages: `You have {{x}} unread messages`,
  deposit_amount_between_x_and_y: `Deposit amount must be between {{x}} and {{y}}`,
  minimum_amount_is_x_y: `Minimum amount is {{x}}{{y}}`,

  // all
  all: `All`,
  title: `Title`,
  date: `Date`,
  type: `Type`,
  status: `Status`,
  remark: `Remark`,
  category: `Category`,
  please_select_x: `Please select {{x}}`,
  save: `Save`,

  change_password: `Change Password`,
  current_password: `Current Password`,
  confirm_password: `Confirm New Password`,
  submit: `Submit`,
  password_changed: `Password Changed`,
  x_must_be_y_characters: `{{x}} must be at least {{y}} characters`,
  reset_password: `Reset Password`,
  casino: `Casino`,
  slots: `Slots`,
  live_casino: `Live Casino`,
  fishing: `Fishing`,
  sports: `Sports`,
  cards: `Cards`,
  clear: `Clear`,
  search: `Search`,
  new: `New`,
  logout: `Logout`,
  choose_date: `ရက်စွဲကို ရွေးပါ။`,
  select_both_date: `Select both Date From and To`,
  from: `ထံမှ`,
  to: `ရန်`,
  apply: `လျှောက်ထားပါ။`,
  promotions: `ဈေးရောင်း`,
  learn_more: `ပိုမိုသိရှိရန်`,
  password_doesnt_match: `စကားဝှက်နှင့် မကိုက်ညီပါ။`,
  cancel: `မလုပ်တော့`,
  confirm_to_pay: `ပေးချေရန် အတည်ပြုပါ။`,
  deposit_amount: `အပ်ငွေပမာဏ`,
  loading_please_wait: `တင်နေသည်၊ ခဏစောင့်ပါ။`,
  account: `အကောင့်`,
  profile: `ကိုယ်ရေးအကျဉ်း`,
  bank_list: `ဘဏ်စာရင်း`,
  in_maintenance: `ထိန်းသိမ်းခြင်း။`,
  birthday: `မွေးနေ့`,
  birthday_bonus: `အပိုဆုတစ်ခုအတွက် သင့်မွေးနေ့ကို ထည့်ပါ။ 🎉🎁`,
  referral_code: `ရည်ညွှန်းကုဒ်`,
  referral: `ရည်ညွှန်းသည်။`,
  my_referral: `ကျွန်ုပ်၏ ရည်ညွှန်းချက်`,
  my_claim: `ကျွန်ုပ်၏ တောင်းဆိုချက်`,
  claim_profit: `အမြတ်ငွေကို တောင်းဆိုပါ။`,
  available_profit: `ရရှိနိုင်သောအမြတ်`,
  claimed_profit: `တောင်းဆိုထားသောအမြတ်`,
  all_time_profit: `အချိန်တိုင်းအမြတ်`,
  referral_note_on_x: `သင့်သူငယ်ချင်းများ {{x}} တွင် ကစားသည့်အခါတိုင်း အမြတ်ရယူပါ`,
  tier: `အဆင့်`,
  commission: `ကော်မရှင်`,
  referred_member: `ရည်ညွှန်းအဖွဲ့ဝင်`,
  profit_earned: `အမြတ်များ`,
  account_information: `အကောင့်အချက်အလက်`,
  invite_friends: `သူငယ်ချင်းများကိုဖိတ်ကြားသည်`,
  how_it_work: `ဘယ်လိုအလုပ်လုပ်လဲ?`,
  invite_note: `သင့်သူငယ်ချင်းသည် သင့်ရည်ညွှန်းကုဒ်ကို အသုံးပြု၍ အကောင့်ဖွင့်သည့်အခါ သီးသန့် ညွှန်းဆိုခွင့်အပိုဆုများ ရယူလိုက်ပါ။`,
  share_via: `တဆင့်မျှဝေပါ။`,
  referral_link: `ရည်ညွှန်းလင့်ခ်`,
  total_profit: `စုစုပေါင်းအမြတ်`,
  copied: `ကူးယူသည်။`,
  nothing_to_claim: `ဘာမှ တောင်းဆိုစရာ မရှိပါဘူး။`,
  claim_history: `တိုင်ကြားမှုမှတ်တမ်း`,
  downline: `ငါ့ဒေါင်းလိုင်း`,
  last_x_digit_bank_ref_number: `Bank Ref နံပါတ်၏ နောက်ဆုံးဂဏန်း {{x}}`,
  bank_ref_number_validation_x: `Bank Ref Number လိုအပ်ပြီး အက္ခရာအရှည် {{x}} ဖြစ်ရပါမည်။`,

  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  // app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  product: `product`,
  invoice: `invoice`,
  // details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default my;
